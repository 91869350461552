@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"); 
@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@300;400;500;700;900&display=swap");

/* body {  */
  /* background-color: #2c292d;
  font-family: "IBM Plex Mono", monospace; */
  /* height: 100vh; */
  /* margin: 0; */
  /* padding: 0; */
  /* box-sizing: border-box;
  font-family: "IBM Plex Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
 /* } */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* .blinkingCursor {
  font-weight: 100;
  color: white;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
} */

/* @keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
} */

/* @-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
} */
/* 
@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
} */

/* @-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
} */
/* 
@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: white;
  }
} */

body::-webkit-scrollbar {
  width: 5px;               /* width of the entire scrollbar */
}
body::-webkit-scrollbar-track {
  background: #303030;        /* color of the tracking area */
}
body::-webkit-scrollbar-thumb {
  
  background-color: black;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
    
}
